import { Locale } from 'date-fns/types';

const mappedLocales: Record<string, string> = {
    'bg-BG': 'bg',
    'cs-CZ': 'cs',
    'da-DK': 'da',
    'de-DE': 'de',
    'en-GB': 'en-GB',
    'es-ES': 'es',
    'et-EE': 'et',
    'fi-FI': 'fi',
    'fr-FR': 'fr',
    'hr-HR': 'hr',
    'hu-HU': 'hu',
    'it-IT': 'it',
    'ja-JP': 'ja',
    'ko-KR': 'ko',
    'lt-LT': 'lt',
    'lv-LV': 'lv',
    'nb-NO': 'nb',
    'nl-NL': 'nl',
    'pl-PL': 'pl',
    'pt-BR': 'pt-BR',
    'pt-PT': 'pt',
    'ro-RO': 'ro',
    'ru-RU': 'ru',
    'sl-SI': 'sl',
    'sr-RS': 'sr',
    'sv-SE': 'sv',
    'th-TH': 'th',
    'tr-TR': 'tr',
    'uk-UA': 'uk',
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW'
}

export const importLocale = async(userLocale: string): Promise<Locale> => {
    let locale;

      switch (userLocale) {
        case 'bg-BG':
          locale = (await import('date-fns/locale/bg')).bg;
          break;
        case 'cs-CZ':
          locale = (await import('date-fns/locale/cs')).cs;
          break;
        case 'da-DK':
          locale = (await import('date-fns/locale/da')).da;
          break;
        case 'de-DE':
          locale = (await import('date-fns/locale/de')).de;
          break;
        case 'en-GB':
            locale = (await import('date-fns/locale/en-GB')).enGB;
            break;
        case 'es-ES':
          locale = (await import('date-fns/locale/es')).es;
          break;
        case 'et-EE':
          locale = (await import('date-fns/locale/et')).et;
          break;
        case 'fi-FI':
          locale = (await import('date-fns/locale/fi')).fi;
          break;
        case 'fr-FR':
          locale = (await import('date-fns/locale/fr')).fr;
          break;
        case 'hr-HR':
          locale = (await import('date-fns/locale/hr')).hr;
          break;
        case 'hu-HU':
          locale = (await import('date-fns/locale/hu')).hu;
          break;
        case 'it-IT':
          locale = (await import('date-fns/locale/it')).it;
          break;
        case 'ja-JP':
          locale = (await import('date-fns/locale/ja')).ja;
          break;
        case 'ko-KR':
          locale = (await import('date-fns/locale/ko')).ko;
          break;
        case 'lt-LT':
          locale = (await import('date-fns/locale/lt')).lt;
          break;
        case 'lv-LV':
          locale = (await import('date-fns/locale/lv')).lv;
          break;
        case 'nb-NO':
          locale = (await import('date-fns/locale/nb')).nb;
          break;
        case 'nl-NL':
          locale = (await import('date-fns/locale/nl')).nl;
          break;
        case 'pl-PL':
          locale = (await import('date-fns/locale/pl')).pl;
          break;
        case 'pt-BR':
          locale = (await import('date-fns/locale/pt-BR')).ptBR;
          break;
        case 'pt-PT':
          locale = (await import('date-fns/locale/pt')).pt;
          break;
        case 'ro-RO':
          locale = (await import('date-fns/locale/ro')).ro;
          break;
        case 'ru-RU':
          locale = (await import('date-fns/locale/ru')).ru;
          break;
        case 'sl-SI':
          locale = (await import('date-fns/locale/sl')).sl;
          break;
        case 'sr-RS':
          locale = (await import('date-fns/locale/sr')).sr;
          break;
        case 'sv-SE':
          locale = (await import('date-fns/locale/sv')).sv;
          break;
        case 'th-TH':
          locale = (await import('date-fns/locale/th')).th;
          break;
        case 'tr-TR':
          locale = (await import('date-fns/locale/tr')).tr;
          break;
        case 'uk-UA':
          locale = (await import('date-fns/locale/uk')).uk;
          break;
        case 'zh-CN':
          locale = (await import('date-fns/locale/zh-CN')).zhCN;
          break;
        case 'zh-TW':
          locale = (await import('date-fns/locale/zh-TW')).zhTW;
          break;
        default:
          locale = (await import('date-fns/locale/en-GB')).enGB;
      }

    return locale;
}

export const getDateFnsMappedLocale = (staffLanguage: string) => {
    return mappedLocales[staffLanguage] || 'en-GB';
}