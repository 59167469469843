import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';

import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useGetStaffInfoQuery } from '../state/user/query';
import { useGetUserViewsQuery } from '../state/views/query';

import GroupsDropdown from '../components/dropdowns/GroupsDropdown';
import ViewsDropdown from '../components/dropdowns/ViewsDropdown';

import '../styles/GeneralStyles.css';
import styles from '../styles/Dashboard.module.css';

import { TdsButton, TdsLink, TdsMessage, TdsSpinner } from '@scania/tegel-react';
import DateTimeRangePicker from '../components/common/DateTimeRangePicker';
import DashboardWidgets from '../components/dashboard/DashboardWidgets';

import FiltersButton from '../components/filters/FiltersButton';
import FiltersContent from '../components/filters/FiltersContent';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const Dashboard = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);

  const { showDashboardPage } = useFlags();

  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedDashboardRef, setSelectedDashboardRef] = useState('');
  const [dashboardStartDate, setDashboardStartDate] = useState(defaultStartDate);
  const [dashboardEndDate, setDashboardEndDate] = useState(defaultEndDate);
  const [dashboards, setDashboards] = useState([]);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  // TODO: remove the firstDashboardRef var below once we have KPIs in gloabl dashboards
  const [firstCustomDashboardRef, setFirstCustomDashboardRef]=useState('');

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    isFetching: isCustomerGroupsFetching,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);

  useEffect(() => {
    if (isStaffLoadingError) {
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData]);

  const searchNewInterval = ({
    startDate,
    endDate,
  }: {
    [key: string]: string;
  }) => {
    setDashboardStartDate(dayjs(startDate).format(dateTimeFormat));
    setDashboardEndDate(dayjs(endDate).format(dateTimeFormat));
  };

  const {
    data: fetchedDashboards,
    isSuccess: isDashboardsLoadedSuccessfully,
    isError: isErrorLoadingDashboards,
    isLoading: isDashboardsLoading,
    isFetching: isDashboardsFetching,
  } = useGetUserViewsQuery(isStaffLoadingSuccess ? null : skipToken);

  useEffect(() => {
    if (isDashboardsLoadedSuccessfully && !isDashboardsFetching && fetchedDashboards?.length) {
      setDashboards(fetchedDashboards);
      // TODO: remove the first custom dashboard ref occurences once we have KPI cards in global dashboards
      const firstCustomDashboard = fetchedDashboards.find((dashboard: Dashboard) => dashboard.title.isTranslationKeyId === false);
      if (firstCustomDashboard) setFirstCustomDashboardRef(firstCustomDashboard.dashboardReference);      
      // TODO: remove the 2 lines above
      const selectedDashboard = fetchedDashboards.find((dashboard: Dashboard) => dashboard.isDefault === true);
      if (selectedDashboard && selectedDashboard.dashboardReference) {
        setSelectedDashboardRef(selectedDashboard.dashboardReference);
      }
    }
  }, [fetchedDashboards, isDashboardsFetching, isDashboardsLoadedSuccessfully]);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const selectedCount = (selectedFuelTypes.length > 0 ? 1 : 0) + (selectedVehicleTypes.length > 0 ? 1 : 0);

  return (
    <div className='mainContainer isolatedContainer'>
      <div className='stickyHeader'>
        <div className='pageHeader'>
          <div className='header2'>{t('Anslagstavla')}</div>
          {loggedIn && staffData && showDashboardPage && (
            <div className={styles.inlineFlexContainer}>
              {(isDashboardsFetching || isDashboardsLoading) && (
                <div className={styles.spinnerContainer}>
                  <TdsSpinner size='md' />
                </div>
              )}
              {isDashboardsLoadedSuccessfully && dashboards.length > 0 && (
                <ViewsDropdown
                  views={dashboards}
                  updateSelectedView={setSelectedDashboardRef}
                  size='md'
                  labelPosition='inside'
                  label={t('Anslagstavla')}
                  modeVariant='secondary'
                />
              )}
              <TdsButton
                type='button'
                variant='primary'
                size='md'
                text={t('Create Dashboard')}
                disabled={true}
              />
            </div>
          )}
        </div>
        {loggedIn && staffData && !isStaffLoading && !showDashboardPage && (
          <div className='pageContentContainer'>{t('DuHarInteBehörighet_')}</div>
        )}
        {loggedIn && showDashboardPage && (
          <div className='pageContentContainer'>
            {(isErrorLoadingGroups || isErrorLoadingDashboards) && (
              <TdsMessage
                variant='error'
                header={t('EttFelHarUppstått_FörsökIgenSenare_')}
              />
            )}

            {(isStaffLoading || isCustomerGroupsFetching) && (
              <div className='spinnerContainer'>
                <TdsSpinner size='md' />
              </div>
            )}

            {staffData && groupsLoadedSuccessfully && (
              <div className={styles.flexContainer}>
                <div className={styles.groupsDropdownContainer}>
                  <GroupsDropdown
                    groups={customerGroups}
                    groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                    onSelection={setSelectedGroup}
                    isDisabled={false}
                    variantMode='primary'
                    label={t('Grupper')}
                    labelPosition='inside'
                  />
                </div>
                <div className={styles.overviewDatePickerContainer}>
                  <DateTimeRangePicker
                    periodStart={dashboardStartDate}
                    periodEnd={dashboardEndDate}
                    staffStartOfWeek={staffData.customer?.startOfWeek}
                    staffLanguage={staffData.language}
                    searchNewInterval={searchNewInterval}
                    modeVariant='primary'
                  />
                </div>
                <div className={styles.filtersButtonContainer}>
                  <FiltersButton
                    isExpanded={isExpanded}
                    onToggle={handleToggle}
                  />
                  {selectedCount > 0 && (
                    <>
                      <span className={styles.filterBadge}>
                        <span>{selectedCount}</span>
                      </span>
                      <TdsLink>
                        <span onClick={() => { setSelectedFuelTypes([]); setSelectedVehicleTypes([]); }} className={styles.clearFiltersButton}>
                          {t('Clear filters')}
                        </span>
                      </TdsLink>
                    </>
                  )}

                </div>
                <div className='fullWidth'>
                  <FiltersContent
                    isExpanded={isExpanded} 
                    onClose={handleClose}
                    selectedFuelTypes={selectedFuelTypes} 
                    setSelectedFuelTypes={setSelectedFuelTypes} 
                    selectedVehicleTypes={selectedVehicleTypes} 
                    setSelectedVehicleTypes={setSelectedVehicleTypes} 
                    externalEquipmentReference={null} 
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {loggedIn && showDashboardPage && staffData && groupsLoadedSuccessfully && (
        <DashboardWidgets
          staffDetails={staffData}
          periodStart={dashboardStartDate}
          periodEnd={dashboardEndDate}
          selectedGroup={selectedGroup}
          selectedDashboardRef={selectedDashboardRef}
          fuelTypes={selectedFuelTypes}
          vehicleTypes={selectedVehicleTypes}
          firstCustomDashboardRef={firstCustomDashboardRef} // TODO: remove when we have KPIs in global dashboards
        />
      )}
    </div>
  );
};

export default Dashboard;
