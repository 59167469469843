import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyPolyfills } from '@myscania/scope/dist/loader';
import { defineCustomElements as scope } from '@myscania/scope/dist/loader';
import { defineCustomElements as loadReactTegel } from '@scania/tegel-react';
import { defineCustomElements as myScaniaChat } from '@myscania/myscania-chat-widget/loader';
import {
  createBrowserRouter,
  RouterProvider,
  RouteObject,
  redirect,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { ShepherdJourneyProvider } from 'react-shepherd';
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import reportWebVitals from './reportWebVitals';
import AuthRedirect from './components/AuthRedirect';
import KeycloakProvider from './keycloak/KeycloakProvider';
import AuthenticationResolverKeycloak from './keycloak/KeycloakAuthenticationResolver';
import Layout from './components/layout/Layout';
import Overview from './pages/Overview';
import FuelReport from './pages/FuelReport';
import Settings from './pages/Settings';
import store from './state/store';
import './index.css';
import './utils/localization';
import 'react-loading-skeleton/dist/skeleton.css'
import Dashboard from './pages/Dashboard';

applyPolyfills().then(() => {
  scope();
});

loadReactTegel();
myScaniaChat();

const tagManagerArgs = {
  gtmId: 'GTM-KJ8BSTFM'
};

if (process.env.REACT_APP_APP_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
}

const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthRedirect>
        <Layout />
      </AuthRedirect>
    ),
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'overview', element: <Overview /> },
      { path: 'fuel-report', element: <FuelReport /> },
      { path: 'settings', element: <Settings /> },
      {
        path: '*',
        loader: () => {
          return redirect('overview');
        },
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

const App = () => (
  <KeycloakProvider>
    <React.StrictMode>
      <Provider store={store}>
        <AuthenticationResolverKeycloak>
          <ShepherdJourneyProvider>
            <RouterProvider router={router} />
          </ShepherdJourneyProvider>
        </AuthenticationResolverKeycloak>
      </Provider>
    </React.StrictMode>
  </KeycloakProvider>
)

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID || ''
})(App)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <LDProvider />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
