export const selectedDashboardSpecMock = {
  'dashboardReference': '0400ed8b-79b4-420d-a97a-cdc5aaa6691b',
  'title': {
    'text': 'Economy',
    'isTranslationKeyId': true
  },
  'widgets': [
    {
      'widgetReference': 'c1db596f-a255-48e5-8f40-ba4130fe04ba',
      'type': 'equipmentUsage',
      'view': 'table',
      'properties': {
        'filters': [],
        'columns': [
          {
            'columnName': 'total_reductant_used',
            'subColumns': [
              'engine.urea.used'
            ]
          },
          {
            'columnName': 'fuel_consumption',
            'subColumns': [
              'vehicle.fuel_liquid2',
              'vehicle.fuel_gaseous2',
              'vehicle.energy'
            ]
          }
        ]
      }
    },
    {
      'widgetReference': '778fc034-ef8e-44bf-96f3-5ecdcd5761f2',
      'type': 'equipmentUsage',
      'view': 'singleStatistic',
      'properties': {
        'filters': [
          {
            'fuelTypes': [
              'Diesel'
            ]
          }
        ],
        'parameter': 'vehicle.trailer.distance',
        'statistic': 'average'
      }
    },
    {
      'widgetReference': '778fc034-ef8e-44bf-96f3-5ecdcd5761f1',
      'type': 'equipmentUsage',
      'view': 'singleStatistic',
      'properties': {
        'filters': [],
        'parameter': 'vehicle.odometer.icl_view',
        'statistic': 'sum'
      }
    },
    {
      'widgetReference': 'e8bdc2f8-4b7d-4519-a14d-4e6c72d8cffc',
      'type': 'equipmentUsage',
      'view': 'table',
      'properties': {
        'filters': [],
        'columns': [
            {
                'columnName': 'odometer',
                'subColumns': [
                    'vehicle.odometer.icl_view'
                ]
            },
            {
                'columnName': 'driven_distance',
                'subColumns': [
                    'vehicle.odometer'
                ]
            },
            {
                'columnName': 'fuel_consumption',
                'subColumns': [
                    'vehicle.fuel_liquid2',
                    'custom.conditional.my_scania_setting.total.fuel_liquid.consumption_rate'
                ]
            },
            {
                'columnName': 'fuel_consumption_idling',
                'subColumns': [
                    'vehicle.without_pto.idle.fuel_liquid2',
                    'custom.conditional.my_scania_setting.idling.fuel_liquid.consumption_rate'
                ]
            },
            {
                'columnName': 'total_reductant_used',
                'subColumns': [
                    'engine.urea.used',
                    'custom.conditional.my_scania_setting.engine.urea.consumption_rate'
                ]
            },
            {
                'columnName': 'average_weight',
                'subColumns': [
                    'custom.calculated.vehicle.weight.average'
                ]
            }
        ]
      }
    },
    {
      'widgetReference': '778fc034-ef8e-44bf-96f3-5ecdcd5761f8',
      'type': 'equipmentUsage',
      'view': 'singleStatistic',
      'properties': {
        'filters': [],
        'parameter': 'custom.calculated.speed.average',
        'statistic': 'average'
      }
    },
    {
      'widgetReference': 'f23e7fdc-6817-413a-887b-45925216c0e5',
      'type': 'equipmentUsage',
      'view': 'table',
      'properties': {
        'filters': [
          {
            'fuelTypes': [
              'Electric'
            ]
          }
        ],
        'columns': [
            {
                'columnName': 'fuel_consumption',
                'subColumns': [
                    'vehicle.energy',
                    'custom.conditional.my_scania_setting.total.energy.consumption_rate'
                ]
            },
            {
                'columnName': 'fuel_consumption_idling',
                'subColumns': [
                    'vehicle.without_pto.idle.energy'
                ]
            },
            {
                'columnName': 'transport_work',
                'subColumns': [
                    'custom.calculated.vehicle.transport_work.per_energy'
                ]
            },
            {
                'columnName': 'average_weight',
                'subColumns': [
                    'custom.calculated.vehicle.weight.average'
                ]
            },
            {
                'columnName': 'driven_distance',
                'subColumns': [
                    'vehicle.odometer'
                ]
            }
        ]
      }
    }
  ]
}
