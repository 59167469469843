import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { uuidv4 } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || '';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SETTINGS_API_BASE_URL}/staff/FMPNotifications`,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStaffSettings: builder.query({
      query: () => '',
    }),
  }),
});

export const { useGetStaffSettingsQuery } = settingsApi;
