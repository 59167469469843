import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { uuidv4, formatDateToUTC } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || 'vp';

export const equipmentsApi = createApi({
  reducerPath: 'equipmentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_VP_BFF_HOST}/api/v1/widget/equipmentsummary`,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEquipments: builder.query({
      query: ({
        queryStart,
        queryStop,
        propulsionConsumptionUnit,
        gasConsumptionUnit,
        externalEquipmentGroupReference,
        equipmentTypes,
        fuelTypes,
        utcDiff,
        externalEquipmentReference,
        parameters
      }) => ({
        url: externalEquipmentReference ? `/table/${externalEquipmentReference}` : '/table',
        method: 'POST',
        params: {
          queryStart: formatDateToUTC(queryStart, utcDiff),
          queryStop: formatDateToUTC(queryStop, utcDiff),
          propulsionConsumptionUnit,
          gasConsumptionUnit,
          externalEquipmentGroupReference,
          equipmentTypes:
            equipmentTypes.length > 0 ? equipmentTypes : undefined,
          fuelTypes: fuelTypes.length > 0 ? fuelTypes : undefined,          
        },
        body: JSON.stringify({parameters: parameters})
      }),
    }),
    getSingleStatistic: builder.query({
      query: ({
        parameter,
        statistic,
        queryStart,
        queryStop,
        utcDiff,
        propulsionConsumptionUnit,
        gasConsumptionUnit,
        fuelTypes,
        equipmentTypes,
        externalEquipmentGroupReference
      }) => ({
        url: `/singlestatistic/${parameter}/statistic/${statistic}`,
        method: 'GET',
        params: {
          queryStart: formatDateToUTC(queryStart, utcDiff),
          queryStop: formatDateToUTC(queryStop, utcDiff),
          propulsionConsumptionUnit,
          gasConsumptionUnit,
          externalEquipmentGroupReference,
          equipmentTypes:
            equipmentTypes.length > 0 ? equipmentTypes : undefined,
          fuelTypes: fuelTypes.length > 0 ? fuelTypes : undefined,
        }
      })
    })
  }),
});

export const { useGetEquipmentsQuery, useGetSingleStatisticQuery } = equipmentsApi;