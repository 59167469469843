import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';
import { TdsDropdownCustomEvent } from '@scania/tegel';

import { useUpdateDefaultDashboardMutation } from '../../state/views/query';
import '../../styles/Overview.css';
import { stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { getNameTranslationKey } from '../../utils/report/convertTranslationKeys';

type Props = {
  views: View[];
  updateSelectedView: Function;
  placeholder?: string;
  labelPosition?: 'inside' | 'outside';
  label?: string;
  size?: 'md' | 'xs' | 'sm' | 'lg';
  modeVariant?: 'primary' | 'secondary';
};

const ViewsDropdown = ({
  views = [],
  updateSelectedView,
  placeholder,
  labelPosition,
  label,
  size,
  modeVariant,
}: Props) => {
  const [defaultSelectedView, setDefaultSelectedView] = useState(
    views.find((view) => view.isDefault === true) || views[0]
  );
  const viewsDropdownRef = useRef<HTMLTdsDropdownElement>(null);
  const [updateDefaultDashboard] = useUpdateDefaultDashboardMutation();
  const { t } = useTranslation();

  const handleSelectOption = useCallback(
    (e: TdsDropdownCustomEvent<{ name: string; value: string }>) => {
      let currentDefaultOption = views.find((view) => view.isDefault);
      let selectedOption = views.find(
        (view) => view.dashboardReference === e.detail.value
      );

      if (currentDefaultOption && selectedOption && currentDefaultOption.dashboardReference !== selectedOption.dashboardReference) {
        updateDefaultDashboard(selectedOption.dashboardReference);
      }

      updateSelectedView(e.detail.value);
    },
    [updateDefaultDashboard, updateSelectedView, views]
  );

  useEffect(() => {
    const defaultView = views.find((view) => view.isDefault === true);
    if (defaultView) {
      setDefaultSelectedView(defaultView);
    }
  }, [views]);

  useEffect(() => {
    const viewsDropdown = viewsDropdownRef.current;
    if (!viewsDropdown) return;

    viewsDropdown.addEventListener('tdsChange', handleSelectOption);

    return () => {
      viewsDropdown.removeEventListener('tdsChange', handleSelectOption);
    };
  }, [handleSelectOption]);

  return (
    <div className='viewsDropdownContainer'>
      <TdsDropdown
        ref={viewsDropdownRef}
        placeholder={placeholder || t('Välj')}
        defaultValue={defaultSelectedView.dashboardReference}
        label={label || t('ÄndraVy')}
        label-position={labelPosition || 'outside'}
        modeVariant={modeVariant || 'primary'}
        size={size || 'md'}>
        {views.map((view) => (
          <TdsDropdownOption
            key={view.dashboardReference}
            value={view.dashboardReference}>
            {view.title.isTranslationKeyId
              ? stripNonAlphaCharacters(
                  t(getNameTranslationKey(view.title.text))
                )
              : view.title.text}
          </TdsDropdownOption>
        ))}
      </TdsDropdown>
    </div>
  );
};

export default ViewsDropdown;
