import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useGetStaffInfoQuery } from '../state/user/query';
import { retrieveFederationGatewayID } from '../utils/launchDarkly';

const AuthRedirect = ({ children }: Children) => {
  const { initialized, keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const ldClient = useLDClient();

  const {data: staffDetails, isSuccess: isStaffLoadingSuccess} = useGetStaffInfoQuery(null)

  useEffect(() => {
    if (keycloak && initialized && keycloak.token && keycloak.idTokenParsed && isStaffLoadingSuccess) {
      const { firstName, lastName, externalStaffReference, language } = staffDetails
      const { externalCustomerReference, name, country } = staffDetails.customer

      ldClient?.identify({
        // The multi-context version will be implemented at a later stage, when defining own context kinds will be available
        
        // kind: 'multi',
        // user: {
        //   key: retrieveFederationGatewayID(keycloak.idTokenParsed),
        //   name: `${firstName} ${lastName}`,
        //   externalStaffReference: externalStaffReference,
        //   language: language,
        //   account: keycloak.idTokenParsed.idp_username
        // },
        // organization: {
        //   key: externalCustomerReference,
        //   externalCustomerReference: externalCustomerReference,
        //   name: name,
        //   country: country,
        // }

        kind: 'user',
        key: retrieveFederationGatewayID(keycloak.idTokenParsed),
        name: `${firstName} ${lastName}`,
        externalStaffReference: externalStaffReference,
        language: language,
        account: keycloak.idTokenParsed.idp_username,
        externalCustomerReference: externalCustomerReference,
        companyName: name,
        market: country,
      })
    }
  }, [initialized, isStaffLoadingSuccess, keycloak, ldClient, staffDetails])

  useEffect(() => {
    if (keycloak && initialized && !keycloak.token) {
      keycloak.login({
        redirectUri: `${process.env.REACT_APP_HOST}/overview`,
      });
    } else if (location.pathname === '/') {
      navigate('/overview');
    } else {
      navigate(`${location.pathname}${location.search}`);
    }
  }, [initialized, keycloak, location.pathname, location.search, navigate]);
  return children;
};

export default AuthRedirect;
