import { useCallback, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import { getEnvironmentShortDesc } from '../../utils/infra';
import { useAppDispatch } from '../../state/store';
import { userApi } from '../../state/user/query';

type Props = {
  language: string;
};

const Header = ({ language }: Props) => {
  const [isTokenDispatched, setIsTokenDispatched] = useState(false);
  const { t } = useTranslation();
  const { initialized, keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);
  const environmentName = getEnvironmentShortDesc();

  const dispatch = useAppDispatch();

  const tryLogout = useCallback(
    (e: any) => {
      e.preventDefault();
      if (keycloak && initialized) {
        keycloak.logout();
        sessionStorage.clear();
        dispatch(userApi.util.resetApiState());
      }
    },
    [dispatch, initialized, keycloak]
  );

  const goToPrivacyPage = () => {
    window.open(`${process.env.REACT_APP_MY_SCANIA_LINK}/#/privacy`, '_blank');
  };

  useEffect(() => {
    const updateNavbarToken = (event: any) => {
      if (event.detail === true && loggedIn) {
        setTimeout(() => {
          document.dispatchEvent(
            new CustomEvent('navbar_UpdateToken', {
              detail: keycloak?.token,
            })
          );
          setIsTokenDispatched(true);
        }, 100);
      }
    };

    document.addEventListener('navbarHeaderReady', updateNavbarToken);

    return () => {
      document.removeEventListener('navbarHeaderReady', updateNavbarToken);
    };
  }, [keycloak?.token, loggedIn]);

  useEffect(() => {
    window.addEventListener('navbarAccountLogOut', tryLogout);

    return () => {
      window.removeEventListener('navbarAccountLogOut', tryLogout);
    };
  }, [tryLogout]);

  return (
    <div>
    <sss-navbar-header culture={language} environment={environmentName}>
      <sss-navbar-application-brand>
        <span>{t('Fordonsuppföljning')}</span>
      </sss-navbar-application-brand>

      <div className='sss-navbar-menu-divider'></div>
      <div>
        {loggedIn && (
          <sss-navbar-header-navigation></sss-navbar-header-navigation>
        )}
      </div>
      <div>
        {loggedIn && isTokenDispatched && (
          <sss-navbar-tour-header
            environment={environmentName}
            show-reset={true}></sss-navbar-tour-header>
        )}
      </div>
      <div>
        {loggedIn && isTokenDispatched && (
          <navbar-header-notifications
            label={t('Aviseringar')}
            heading={t('Aviseringar')}>
            <notifications-container
              culturecode={language}
              loggedin={loggedIn}
              environment={environmentName}
              systemname='FmsVehiclePerformance'></notifications-container>
          </navbar-header-notifications>
        )}
      </div>

      <div>
        <sss-navbar-help></sss-navbar-help>
      </div>
      <div>
        {loggedIn && <sss-navbar-app-launcher></sss-navbar-app-launcher>}
      </div>
      <div>
        {loggedIn && (
          <sss-navbar-account>
            <li
              className='sss-navbar__list-group-item sss-navbar__dropdown-item'
              onClick={goToPrivacyPage}>
              <span>{t('Gdpr_Integritetspolicy')}</span>
            </li>
          </sss-navbar-account>
        )}
      </div>

      <sss-navbar-brand></sss-navbar-brand>
    </sss-navbar-header>
    </div>
  );
};

export default Header;
