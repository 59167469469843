import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authenticationReducer from './authentication/slice';
import localSettingsReducer from './localSettings/slice';
import { userApi } from './user/query';
import { fuelReportApi } from './fuelReport/query';
import { emissionSpecsApi } from './emissionSpecifications/query';
import { viewsApi } from './views/query';
import { groupsApi } from './groups/query';
import { equipmentsApi } from './equipments/query';
import { driverDetailsApi } from './driverDetails/query';
import { settingsApi } from './settingsApi/query';

export const rootReducer = {
  authentication: authenticationReducer,
  [userApi.reducerPath]: userApi.reducer,
  [fuelReportApi.reducerPath]: fuelReportApi.reducer,
  [emissionSpecsApi.reducerPath]: emissionSpecsApi.reducer,
  [viewsApi.reducerPath]: viewsApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [equipmentsApi.reducerPath]: equipmentsApi.reducer,
  [driverDetailsApi.reducerPath]: driverDetailsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  localSettings: localSettingsReducer,
};

export const middlewares = [
  userApi.middleware,
  fuelReportApi.middleware,
  emissionSpecsApi.middleware,
  viewsApi.middleware,
  groupsApi.middleware,
  equipmentsApi.middleware,
  driverDetailsApi.middleware,
  settingsApi.middleware
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middlewares),
  devTools: process.env.REACT_APP_APP_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
