import { TdsButton, TdsIcon } from '@scania/tegel-react';
import React from 'react';
import '../../styles/Filters.module.css';

interface FiltersButtonProps {
  isExpanded: boolean;
  onToggle: () => void;
}

const FiltersButton = ({ isExpanded, onToggle }: FiltersButtonProps) => {
  return (
    <TdsButton
      className="filters-button"
      modeVariant='primary'
      variant='secondary'
      size='md'
      onClick={onToggle}
    >
      <TdsIcon slot='icon' size='20px' name='filters' />
    </TdsButton>
  );
};

export default FiltersButton;