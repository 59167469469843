import i18n from './localization'
import { stripNonAlphaCharacters } from './report/tableUtils';

const getDistanceUnit = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'miles';
  }
  return 'kilometers';
}

const getWeightUnit = () => { return 'tonnes'; }

const getTimeUnit = () => { return 'seconds'; }

const getCountUnit = () => { return 'count'; }

const getPercentageUnit = () => { return '%'; }

const getCountPerDistanceUnit = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'count/100miles';
  }
  return 'count/100km';
}

const getDistancePercentageUnit = () => { return 'distancePercent'; }

const getTimePercentageUnit = () => { return 'timePercent'; }

const getDistancePerTimeUnit = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'miles/h';
  }
  return 'km/h';
}

const getVolumeForDiesel = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'gallons';
  }
  return 'liters';
}

const getVolumeForGas = (gasUnit: string) => {
  if (gasUnit.toLowerCase() === 'nm3') {
    return 'Nm3';
  }
  return 'kilograms';
}

const getVolumeForElectric = () => { return 'KWh'; }

const getVolumeForDieselPerTime = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'VPu_gallonsh';
  }
  return 'Liter_H';
}

const getVolumeForGasPerTime = (gasUnit: string) => {
  if (gasUnit.toLowerCase() === 'nm3') {
    return 'VPu_Nm3h';
  }
  return 'VPu_kgh';
}

const getVolumeForElectricPerTime = () => {
  return 'VPu_kWhh';
}


const getTransportWorkUnit = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'VPu_tonnemiles';
  }
  return 'Tonkm';
}

const getTransportWorkPerDiesel = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') { return 'Tonmiles_Gal'; }  
  return 'Tonkm_L';
}

const getTransportWorkPerGas = (propulsionConsumptionUnit: string, gasUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    if (gasUnit.toLowerCase() === 'nm3') {
      return 'VPu_tonnesmilesNm3';
    }
    return 'Tonmiles_Kg';
  }
  else {    
    if (gasUnit.toLowerCase() === 'nm3') {
      return 'VPu_tonnekmNm3';
    }
    return 'Tonkm_Kg';
  }
}

const getTransportWorkPerElectric = (propulsionConsumptionUnit: string) => {
  if (propulsionConsumptionUnit.toLowerCase() === 'milesperprop') {
    return 'VPu_tonnesmileskWh';
  }
  return 'VPu_tonneskmkWh';
}

const getSupportScoreUnit = (supportScoreType: string) => {
  // normally we should only send the key to be translated, but we don't have % in the key for 3 support score labels so we need to combine 2 keys
  switch (supportScoreType) {
    case 'anticipation': return 'Förutseende___';
    case 'hill': return 'Backkörning___';
    case 'gear': return `${i18n.t('Växelval')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    case 'brake': return `${i18n.t('VP_RegenerativeBrake')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    case 'wear': return `${i18n.t('Bromsanvändning')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    default: return 'GenomsnittspoängI_';
  }
}

const getEquipmentInfoUnit = (equipmentInfoMetric: string) => {
  switch (equipmentInfoMetric) {
    case 'brand': return 'Tillverkare';
    case 'fuelType': return 'Bränsletyp';
    case 'equipmentType': return 'Utrustningstyp';
    case 'engineDesignation': return 'TH_Motor';
    case 'model': return 'Modell';
    case 'propulsionSystem': return 'DE_PropulsionType';
    default: return equipmentInfoMetric;
  }
}

const getVolumeForDieselVsDistance = (propulsionConsumptionUnit: string) => {
  switch (propulsionConsumptionUnit.toLowerCase()) {
    case 'milesperprop': return 'Mpg';
    case 'propper100km': return 'L_100Km';
    default: return 'Km_L';
  }
}

const getVolumeForGasVsDistance = (propulsionConsumptionUnit: string, gasUnit: string) => {
  if (gasUnit.toLowerCase() === 'nm3') {
    switch (propulsionConsumptionUnit.toLowerCase()) {
      case 'milesperprop': return 'VPu_milesNm3';
      case 'propper100km': return 'VPu_Nm3100km';
      default: return 'VPu_kmNm3';
    }
  }
  // default 'kg' is selected as gas unit
  switch (propulsionConsumptionUnit.toLowerCase()) {
    case 'milesperprop': return 'Miles_Kg';
    case 'propper100km': return 'Kg_100Km';
    default: return 'Km_Kg';
  }
}

const getVolumeForElectricVsDistance = (propulsionConsumptionUnit: string) => {
  switch (propulsionConsumptionUnit.toLowerCase()) {
    case 'milesperprop': return 'Miles_KWh';
    case 'propper100km': return 'KWh_100Km';
    default: return 'Km_KWh';
  }
}

const getReductantConsumptionForLiquidAndDistance = (propulsionConsumptionUnit: string) => {
  switch (propulsionConsumptionUnit.toLowerCase()) {
    case 'milesperprop': return 'Mpg';
    case 'propper100km': return 'L_100Km';
    default: return 'Km_L';
  }
}

export const getUnitBasedOnProfileSettings = (
      param: { name: string, unitType: string } = { name: '', unitType: ''},
      propulsionConsumptionUnit: string = 'propper100km',
      gasUnit: string = 'Kg') => {
  switch (param.unitType) {
    case 'distancePerTime': return getDistancePerTimeUnit(propulsionConsumptionUnit);
    case 'countPerDistance': return getCountPerDistanceUnit(propulsionConsumptionUnit);
    case 'count': return getCountUnit();
    case 'percentage': return getPercentageUnit();
    case 'time': return getTimeUnit();
    case 'timePercentage': return getTimePercentageUnit();
    case 'distance': return getDistanceUnit(propulsionConsumptionUnit);
    case 'distancePercentage': return getDistancePercentageUnit();
    case 'weight': return getWeightUnit();
    case 'volumeForDiesel': return getVolumeForDiesel(propulsionConsumptionUnit);
    case 'volumeForGas': return getVolumeForGas(gasUnit);
    case 'volumeForElectric': return getVolumeForElectric();
    case 'volumeForDieselPerTime': return getVolumeForDieselPerTime(propulsionConsumptionUnit);
    case 'volumeForGasPerTime': return getVolumeForGasPerTime(gasUnit);
    case 'volumeForElectricPerTime': return getVolumeForElectricPerTime();
    case 'volumeForDieselVsDistance': return getVolumeForDieselVsDistance(propulsionConsumptionUnit);
    case 'volumeForGasVsDistance': return getVolumeForGasVsDistance(propulsionConsumptionUnit, gasUnit);
    case 'volumeForElectricVsDistance': return getVolumeForElectricVsDistance(propulsionConsumptionUnit);
    case 'reductantForDistance': return getReductantConsumptionForLiquidAndDistance(propulsionConsumptionUnit);
    case 'transportWork': return getTransportWorkUnit(propulsionConsumptionUnit);
    case 'transportWorkForDiesel': return getTransportWorkPerDiesel(propulsionConsumptionUnit);
    case 'transportWorkForGas': return getTransportWorkPerGas(propulsionConsumptionUnit, gasUnit);
    case 'transportWorkForElectric': return getTransportWorkPerElectric(propulsionConsumptionUnit);
    case 'supportScoreTotal': return getSupportScoreUnit('total');
    case 'supportScoreHill': return getSupportScoreUnit('hill');
    case 'supportScoreAnticipation': return getSupportScoreUnit('anticipation');
    case 'supportScoreGear': return getSupportScoreUnit('gear');
    case 'supportScoreBrake': return getSupportScoreUnit('brake');
    case 'supportScoreWear': return getSupportScoreUnit('wear');
    case 'infoBrand': return getEquipmentInfoUnit('brand');
    case 'infoFuelType': return getEquipmentInfoUnit('fuelType');
    case 'infoEquipmentType': return getEquipmentInfoUnit('equipmentType');
    case 'infoEngineDesignation': return getEquipmentInfoUnit('engineDesignation');
    case 'infoModel': return getEquipmentInfoUnit('model');
    case 'infoPropulsion': return getEquipmentInfoUnit('propulsionSystem');
    default: return 'Okänd'; // will translate to 'unknown' in the create view modal
  }
}