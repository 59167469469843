import { TdsButton, TdsIcon } from '@scania/tegel-react';

import { createFuelReportExcelWorkbook } from '../../utils/report/fuelReportExport';
import styles from '../../styles/FuelReportExportButton.module.css'

type Props = {
  buttonText: string;
  buttonType: 'primary' | 'secondary' | 'ghost' | 'danger';
  disabled: boolean;
  sheetTitle: string;
  tableData: FuelReportItem[];
  staffDetails: StaffDetails;
  interval: {
    startDate: string;
    endDate: string;
  };
};

const FuelReportExportButton = ({
  buttonText,
  buttonType,
  disabled,
  sheetTitle,
  tableData,
  staffDetails,
  interval,
}: Props) => (
    <TdsButton
      className={styles.exportButton}
      type='button'
      variant={buttonType}
      mode-variant='primary'
      size='sm'
      disabled={disabled}
      text={buttonText}
      onClick={() => createFuelReportExcelWorkbook({
        sheetTitle,
        tableData,
        staffDetails,
        interval,
      })}
    >
      <TdsIcon slot='icon' size='16px' name='export'></TdsIcon>
    </TdsButton>
  );

export default FuelReportExportButton;
