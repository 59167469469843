import { skipToken } from '@reduxjs/toolkit/query';
import { useGetDashboardSpecificationQuery } from '../../state/views/query';
import { TdsMessage, TdsSpinner } from '@scania/tegel-react';
import { useTranslation } from 'react-i18next';
import TableWidget from '../common/TableWidget';
import KPICard from '../common/KPICard';
import '../../styles/DashboardWidgets.css';

import { selectedDashboardSpecMock } from './mock';

type DashboardWidgetsProps = {
  staffDetails: StaffDetails;  
  periodStart: string;
  periodEnd: string;
  selectedGroup?: string;
  selectedDashboardRef: string;
  externalEquipmentRef?: string | null;
  fuelTypes: string[];
  vehicleTypes: string[];
  firstCustomDashboardRef: string; // TODO: remove when we have KPIs in global dashboards
}

const DashboardWidgets = ({
  staffDetails,
  periodStart,
  periodEnd,
  selectedGroup = 'groupFilterAll',
  selectedDashboardRef,
  externalEquipmentRef,
  fuelTypes,
  vehicleTypes,
  firstCustomDashboardRef = '' // TODO: remove when we have KPIs in gloabl dashboards
}: DashboardWidgetsProps) => { 
  const { t } = useTranslation();
  const {
    data: selectedDashboardSpecs,
    isSuccess: dashboardSpecsLoadedSuccessfully,
    isError: isErrorLoadingDashboardSpecs,
    isFetching: isDashboardSpecsFetching,
    isLoading: isDashboardSpecsLoading,
  } = useGetDashboardSpecificationQuery(
    selectedDashboardRef && selectedDashboardRef.length > 0 ? selectedDashboardRef : skipToken
  );

  const dashboardToDisplay = firstCustomDashboardRef === selectedDashboardSpecs?.dashboardReference ? selectedDashboardSpecMock : selectedDashboardSpecs;

  return <>
    { isErrorLoadingDashboardSpecs &&
      <TdsMessage
        variant='error'
        header={t('EttFelHarUppstått_FörsökIgenSenare_')}
      />
    }
    {(isDashboardSpecsFetching ||isDashboardSpecsLoading) && (
      <div className='spinnerContainer'>
        <TdsSpinner size='md' />
      </div>
    )}
    { dashboardSpecsLoadedSuccessfully && selectedDashboardSpecs.widgets?.length > 0 &&
      <>
        <div className='kpiWidgetsContainer'>
          {dashboardToDisplay?.widgets?.filter((singleWidget: { view: string; }) => singleWidget?.view?.toLowerCase() === 'singlestatistic')?.map((singleWidget: any, singleWidgetIndex: number) => (
            <div className='kpiContainer'
                key={`singleKPIWidget_${singleWidgetIndex}`}>
              <KPICard
                staffDetails={staffDetails}
                parameter={singleWidget.properties.parameter}
                statistic={singleWidget.properties.statistic}
                queryStart={periodStart}
                queryStop={periodEnd}
                selectedGroup={selectedGroup}
                externalEquipmentRef={externalEquipmentRef}
                fuelTypes={fuelTypes}
                vehicleTypes={vehicleTypes}
              />
            </div>
          ))}
        </div>
        <div className='tableWidgetsContainer'>
          {dashboardToDisplay?.widgets?.filter((singleWidget: { view: string; }) => singleWidget?.view?.toLowerCase() === 'table')?.map((singleWidget: any, singleWidgetIndex: number) => (
            <TableWidget
              key={`singleTableWidget_${singleWidgetIndex}`}
              staffDetails={staffDetails}
              tableWidgetSpecs={singleWidget}
              periodStart={periodStart}
              periodEnd={periodEnd}
              selectedGroup={selectedGroup}
              externalEquipmentRef={externalEquipmentRef}
              fuelTypes={fuelTypes}
              vehicleTypes={vehicleTypes}
              isSelectedDashboardNotEditable={selectedDashboardSpecs?.title?.isTranslationKeyId || false}
            />
          ))}
        </div>
      </>
    }
  </>
}

export default DashboardWidgets;