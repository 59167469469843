import { useEffect, useRef } from 'react';
import { TdsCard, TdsMessage } from '@scania/tegel-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useGetSingleStatisticQuery } from '../../state/equipments/query';
import { unitToTranslationKey } from '../../utils/report/convertTranslationKeys';
import { displayFormattedValue, transformToAcceptedGroupPayload } from '../../utils/report/tableUtils';

import styles from '../../styles/KPICard.module.css';
import { capitalizeFirstLetter } from '../../utils/general';

type KPICardProps = {
    staffDetails: StaffDetails;
    parameter: string;
    statistic: 'sum' | 'average';
    queryStart: string;
    queryStop: string;
    selectedGroup: string;
    externalEquipmentRef?: string | null;
    fuelTypes: string[];
    vehicleTypes: string[];
};

const KPICard = ({
    staffDetails,
    parameter,
    statistic,
    queryStart,
    queryStop,
    selectedGroup,
    externalEquipmentRef,
    fuelTypes,
    vehicleTypes,
}: KPICardProps) => {
    const { t } = useTranslation();
    const cardRef = useRef<HTMLTdsCardElement>(null);

    const canRunQuery = Boolean(parameter && queryStart && queryStop);

    const {
        data: KPIData,
        isLoading: isLoadingKPIData,
        isFetching: isFetchingKPIData,
        isError: isErrorLoadingKPIData,
    } = useGetSingleStatisticQuery(
        canRunQuery
            ? {
                  parameter,
                  statistic,
                  queryStart,
                  queryStop,
                  utcDiff: staffDetails.customer.utcDiff,
                  propulsionConsumptionUnit: staffDetails.propulsionConsumptionUnit,
                  gasConsumptionUnit: staffDetails.gasConsumptionUnit,
                  fuelTypes,
                  equipmentTypes: vehicleTypes,
                  externalEquipmentGroupReference: transformToAcceptedGroupPayload(selectedGroup),
                  externalEquipmentReference: externalEquipmentRef,
              }
            : skipToken
    );

    const dataStillLoading = isLoadingKPIData || isFetchingKPIData;

    useEffect(() => {
        const shadowRoot = cardRef.current?.shadowRoot;
        if (shadowRoot) {
            const style = document.createElement('style');
            style.textContent = `
                .card {
                    box-shadow: none !important;
                }
            `;
            shadowRoot.appendChild(style);
        }
    }, []);

    return (
        <TdsCard ref={cardRef} className={styles.kpiCard}>
            <div slot='header'>
                <span className='tds-headline-05'>{parameter || ''}</span>
            </div>
            { !isErrorLoadingKPIData &&
                <div slot='subheader' className='fullWidth'>                
                    <div className='tds-headline-06'>
                        {dataStillLoading ?
                            <Skeleton /> :
                            <div className={styles.statisticSubheader}>
                                {KPIData?.parameter?.unit &&
                                    <>
                                        {capitalizeFirstLetter(t(unitToTranslationKey(KPIData?.parameter?.unit)))}
                                        <div className={styles.pointDivider}>.</div>
                                    </>
                                }
                                {KPIData.statistic.toLowerCase() === 'average' ? t('TF_FleetAverage') : t('Total')}
                            </div>
                        }
                    </div>
                </div>
            }
            <div slot='body' className={styles.cardBody}>
            { isErrorLoadingKPIData ?
                <TdsMessage
                    variant='error'
                    header={t('EttFelHarUppstått_FörsökIgenSenare_')}
                />
                :
                <span className='tds-headline-01'>
                    {dataStillLoading && !isErrorLoadingKPIData && <Skeleton />}
                    {!dataStillLoading &&
                    !isErrorLoadingKPIData &&
                    KPIData &&
                        displayFormattedValue(KPIData?.parameter).toLocaleString()}
                </span>
            }
            </div>
        </TdsCard>
    );
};

export default KPICard;
