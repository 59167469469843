import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsCheckbox, TdsIcon, TdsTooltip } from '@scania/tegel-react';
import { stripBraces, stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { headerTitleToTranslationKey, unitToTranslationKey } from '../../utils/report/convertTranslationKeys';
import { groupsExplanationsKeys } from '../../utils/report/paramsGroups';
import { getUnitBasedOnProfileSettings } from '../../utils/unitsCategories';
import styles from '../../styles/ParameterGroupsContainer.module.css';
import { anyUnitContainsSearchedText } from '../../utils/views';

type ParameterContainerProps = {
  categoryIndex: number;
  categoryNameContainsSearchedText: boolean;
  groups: any;
  propulsionConsumptionUnit: string;
  gasUnit: string;
  viewMetrics: Array<{
    columnName: string;
    subColumns: Array<string>
  }>;
  expandedParameterGroups: Array<string>;
  updateParamsList: Function;
  searchedText: string;
  updateVisibleCategoryGroups: Function;
  setCheckboxMarked: Function;
  updateExpandedParamGroups: Function;
}

const ParameterGroupsContainer = ({
      categoryIndex,
      categoryNameContainsSearchedText,
      groups = {},
      propulsionConsumptionUnit,
      gasUnit,
      viewMetrics,
      expandedParameterGroups,
      updateParamsList,
      searchedText,
      updateVisibleCategoryGroups,
      setCheckboxMarked,
      updateExpandedParamGroups
    } : ParameterContainerProps) => {
  const { t } = useTranslation();
  const groupKeys: any = Object.keys(groups);
  const userUnitsMeasureSystem = propulsionConsumptionUnit.includes('miles') ? 'imperial' : 'metric';
  const checkboxElements = useRef<Array<HTMLTdsCheckboxElement | null>>([]);
  let visibleGroupsUnderCategory = 0;

  const toggleExpandCollapse = (groupId: string) => {
    updateExpandedParamGroups(expandedParameterGroups.includes(groupId) ?
      expandedParameterGroups.filter(element => element !== groupId)
      : [...expandedParameterGroups, groupId]);
  }

  const checkIndetermination = (metricsColumnName: string) => {
    const currentMetricsGroup = viewMetrics.find(group => group.columnName === metricsColumnName);
    if (typeof currentMetricsGroup !== 'undefined' && groups[metricsColumnName]) {
      const totalunitsForGroup = groups[metricsColumnName].length || 0;
      return currentMetricsGroup.subColumns.length < totalunitsForGroup;
    }
    return false;
  }

  const markCheckboxes = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!event.detail.checkboxId || (event.detail.type === 'change')) {
        return;
      }

      const newCheckedStatus = event.detail.checked;
      const checkboxValue = event.detail.value;
      const checkboxName = event.target.name;
      const isParentGroup = !checkboxName?.includes('__');

      if (isParentGroup) {
        // we have a parent group checkbox so need to propagate its checked status to all its children
        document.querySelectorAll(`tds-checkbox[name^=${CSS.escape(checkboxValue)}__]`).forEach((childCheckBox: HTMLTdsCheckboxElement | any) => {
          childCheckBox.checked = newCheckedStatus;
        });
        // expand parent group when user marks it as checked        
        if (event?.target?.id?.length > 0 && newCheckedStatus) {
          const checkboxId = event?.target?.id || '';
          const categoryIdentifier = checkboxId.substring(0, checkboxId.indexOf('_checkbox_'))
          const constructedGroupLabel = `${categoryIdentifier}_${checkboxId.substring(checkboxId.indexOf('checkbox_') + 9)}_container`;
          
          if (!expandedParameterGroups.includes(constructedGroupLabel)) {
            updateExpandedParamGroups([...expandedParameterGroups, constructedGroupLabel]);
          }
        }
      }
      else {
        // we have a child param so need to update the parent checkbox
        const parentCheckboxName = checkboxName.substring(0, checkboxName.indexOf('__')) || '';
        const parentCheckboxElement: HTMLTdsCheckboxElement | null = document.querySelector(`tds-checkbox[name=${CSS.escape(parentCheckboxName)}]`);
        const siblingCheckboxesUnderParentGroup = document.querySelectorAll(`tds-checkbox[name^=${CSS.escape(parentCheckboxName)}__]`);

        // see how many sibling checkboxes are checked
        let checkedChildCheckboxes = 0;
        siblingCheckboxesUnderParentGroup.forEach((childCheckBox: HTMLTdsCheckboxElement | any) => {
          if (childCheckBox && childCheckBox.checked) { checkedChildCheckboxes++; }
        });

        if (parentCheckboxElement) {
          if (checkedChildCheckboxes === 0) {
            // last child checkbox was just unchecked so we need to also uncheck the parent
            parentCheckboxElement.checked = false;
            parentCheckboxElement.indeterminate = false;
          }
          else {
            if (!parentCheckboxElement.checked) {
              // first child checkbox in group has been checked so we need to mark parent as checked also, but with indeterminate status
              parentCheckboxElement.checked = true;
              parentCheckboxElement.indeterminate = true;
            }
            else {
              // set parent indeterminate status based on number of children checked
              parentCheckboxElement.indeterminate = !(checkedChildCheckboxes === siblingCheckboxesUnderParentGroup.length);
            }
          }
        }
      }

      setCheckboxMarked(true);
      updateParamsList(newCheckedStatus, checkboxValue, checkboxName, isParentGroup);
    }, [updateParamsList, setCheckboxMarked, expandedParameterGroups, updateExpandedParamGroups]
  );

  useEffect(() => {
    checkboxElements.current.forEach(element => {
      if (element) {
        element.addEventListener('tdsChange', markCheckboxes);
      }
    });

    return () => {
      document.removeEventListener('tdsChange', markCheckboxes);
    };
  }, [markCheckboxes]);

  useEffect(() => {
    updateVisibleCategoryGroups(categoryIndex, visibleGroupsUnderCategory)
  }, [categoryIndex, visibleGroupsUnderCategory, updateVisibleCategoryGroups]);

  return <div className={styles.category_groups_container}>
    { groupKeys.map((groupIdentifier: string, groupIndex: any) => {
      const groupNameContainsSearchedText = searchedText?.length ? stripNonAlphaCharacters(t(headerTitleToTranslationKey(groupIdentifier)))?.toLowerCase()?.includes(searchedText?.toLowerCase()) : false;
      const groupUnitsContainSearchedText = searchedText?.length ? anyUnitContainsSearchedText(searchedText, groups[groupIdentifier], propulsionConsumptionUnit, gasUnit) : false;
      const isParamGroupVisible = !searchedText?.length || groupNameContainsSearchedText || groupUnitsContainSearchedText || categoryNameContainsSearchedText;
      visibleGroupsUnderCategory += (isParamGroupVisible ? 1 : 0);      
      const unitsVisible = expandedParameterGroups.includes(`category_${categoryIndex}_${groupIdentifier}_${groupIndex}_container`) || (searchedText?.length > 0 && groupUnitsContainSearchedText);
      
      return isParamGroupVisible &&
        <div
          className={styles.single_group_container}
          id={`category_${categoryIndex}_${groupIdentifier}_${groupIndex}_container`}
          key={`single_group_container_${groupIdentifier}_${groupIndex}`}>
          <div
            className={styles.container_header}
            key={`single_group_container_header_${groupIdentifier}_${groupIndex}`}
            id={`group_label_${groupIdentifier}_${groupIndex}`}
            onClick={() => toggleExpandCollapse(`category_${categoryIndex}_${groupIdentifier}_${groupIndex}_container`)}>
            { unitsVisible ? <TdsIcon name='chevron_down' className='clickableElement' /> : <TdsIcon name='chevron_right' className='clickableElement' />}
            <TdsCheckbox
              value={groupIdentifier}
              name={groupIdentifier}
              id={`category_${categoryIndex}_checkbox_${groupIdentifier}_${groupIndex}`}
              ref={(element) => checkboxElements.current.push(element)}
              key={`parent_group_checkbox_${groupIdentifier}_${groupIndex}`}
              checked={typeof viewMetrics.find(group => group.columnName === groupIdentifier) !== "undefined"}
              indeterminate={checkIndetermination(groupIdentifier)}>
              <div slot='label'>
                {stripNonAlphaCharacters(
                  t(headerTitleToTranslationKey(groupIdentifier))
                )}
              </div>
            </TdsCheckbox>
          </div>
          <TdsTooltip
            placement='top-start'
            selector={`#group_label_${groupIdentifier}_${groupIndex}`}
            mouseOverTooltip={false}
            text=''>
            <div>
              <b>
                {stripNonAlphaCharacters(
                  t(headerTitleToTranslationKey(groupIdentifier))
                )}
              </b>
              <br />
              {t(groupsExplanationsKeys[groupIdentifier][userUnitsMeasureSystem])}
            </div>
          </TdsTooltip>

          <div className={`${styles.group_params_units_container} ${unitsVisible ? '' : 'hiddenElement'}`}>
            { groups[groupIdentifier]?.map((singleUnitParam: { name: string; unitType: string; }, unitParamIndex: number) => {
              const unitContainsSearchedText = searchedText?.length ? stripBraces(
                t(unitToTranslationKey(
                  getUnitBasedOnProfileSettings(singleUnitParam, propulsionConsumptionUnit, gasUnit)
              )))?.toLowerCase()?.includes(searchedText.toLowerCase()) : false;
              return (!searchedText?.length || unitContainsSearchedText || groupNameContainsSearchedText || categoryNameContainsSearchedText) && <div className={styles.single_unit}
                key={`checkbox_container_${groupIdentifier}_${singleUnitParam.name}_${unitParamIndex}`}>
                <TdsCheckbox
                  name={`${groupIdentifier}__${singleUnitParam.name}`}
                  value={singleUnitParam.name || ''}
                  ref={(element) => checkboxElements.current.push(element)}
                  key={`checkbox_${groupIdentifier}_${singleUnitParam.name}_${unitParamIndex}`}
                  checked={viewMetrics.find(group => group.columnName === groupIdentifier)?.subColumns.includes(singleUnitParam.name)}>
                  <div slot='label'>
                    {stripBraces(
                      t(unitToTranslationKey(
                        getUnitBasedOnProfileSettings(singleUnitParam, propulsionConsumptionUnit, gasUnit)
                    )))?.toLowerCase()}
                  </div>
                </TdsCheckbox>
              </div>
            })}
          </div>
        </div>
    })}
  </div>
}

export default ParameterGroupsContainer;
