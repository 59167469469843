import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import {
  displayAvailableDriverIdentifier,
  displayFormattedValue,
  generateSubheaderLabel,
  getAvailableIdentifier,
  getMatchingPropForVehicleName,
  stripNonAlphaCharacters,
} from './tableUtils';
import i18n from '../localization';
import { headerTitleToTranslationKey } from './convertTranslationKeys';

type Props = {
  sheetTitle: string;
  tableData: any;
  tableWidgetColumns: Array<{ columnName: string; subColumns: Array<string> }> | undefined;
  vehicleRow: TableWidgetEquipment | null;
  staffDetails: StaffDetails;
  interval: {
    startDate: string;
    endDate: string;
  };
};

export const createDriverDataExcelWorkbook = async ({
  sheetTitle,
  tableData,
  tableWidgetColumns = [],
  vehicleRow,
  staffDetails,
  interval,
}: Props) => {
  const dateTimeFormat = 'YYYY-MM-DD HH:mm';

  const currentVehicleName = vehicleRow ? getAvailableIdentifier(
    getMatchingPropForVehicleName(staffDetails.vehicleIdentifier),
    vehicleRow?.equipmentInfo
  ) : '';

  const workbook = new ExcelJS.Workbook();
  let date = new Date();
  workbook.creator = 'Scania - Vehicle Performance';
  workbook.created = date;
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible',
    },
  ];

  const reportSheet = workbook.addWorksheet(sheetTitle, {
    properties: { defaultColWidth: 20 },
  });

  const customerNameRow = reportSheet.getRow(1);
  customerNameRow.height = 60;
  customerNameRow.alignment = { vertical: 'middle' };
  customerNameRow.getCell(1).value = staffDetails.customer?.name || '';

  const overviewPeriodRow = reportSheet.getRow(2);
  overviewPeriodRow.height = 60;
  overviewPeriodRow.alignment = { vertical: 'middle' };
  overviewPeriodRow.getCell(1).value = `${currentVehicleName}: ${dayjs(interval.startDate).format(dateTimeFormat)} - ${dayjs(
    interval.endDate
  ).format(dateTimeFormat)}`;

  const headerRow = reportSheet.getRow(4);

  const createTableHeaderRow = () => {
    const tableHeaderRows: any[] = [];

    const translatedEquipmentKey = { param: i18n.t('Utrustning'), count: 1 };
    const dashboardParams = tableWidgetColumns.map(
      (columnDetails) => ({
        param: stripNonAlphaCharacters(
          i18n.t(headerTitleToTranslationKey(columnDetails.columnName))
        ),
        count: columnDetails.subColumns.length,
      })
    );

    tableHeaderRows.push(translatedEquipmentKey, ...dashboardParams);
    return tableHeaderRows;
  };

  const createTableUnitsRow = () => {
    const tableUnitsRow: string[] = [];

    const dashboardUnits = vehicleRow ? vehicleRow.parameters?.map(
      (metric: Metric) => generateSubheaderLabel(metric)
    ) : [];

    tableUnitsRow.push('', ...dashboardUnits.flat(3));
    return tableUnitsRow;
  };

  const createOverallVehicleRow = () => {
    const tableBodyRows: string[][] = [];

    const currentVehicleParamsValues = vehicleRow ? vehicleRow.parameters?.map(
      (metric: Metric) => displayFormattedValue(metric)
    ) : [];

    tableBodyRows.push([
      currentVehicleName,
      ...currentVehicleParamsValues.flat(3),
    ]);

    return tableBodyRows;
  }

  const createTableBodyRows = () => {
    const tableBodyRows: string[][] = createOverallVehicleRow();

    tableData?.forEach((singleDriverRow: any) => {
      const currentDriverName = i18n.t(displayAvailableDriverIdentifier(singleDriverRow));

      const currentDriversParamsValues = vehicleRow ? vehicleRow.parameters?.map(
        (metric: Metric) =>
          displayFormattedValue(singleDriverRow?.parameters?.find((param: Metric) => param.name.toLowerCase() === metric.name.toLowerCase()))
        ) : [];

      tableBodyRows.push([
        currentDriverName,
        ...currentDriversParamsValues.flat(3),
      ]);
    });

    return tableBodyRows;
  };

  const generateTableHeader = () => {
    const tableHeaderCells = createTableHeaderRow();
    let currentColumnNumber = 1;

    for (let i = 0; i < tableHeaderCells.length; i++) {
      if (tableHeaderCells[i].count === 1) {
        let cell = reportSheet.getCell(headerRow.number, currentColumnNumber);
        cell.value = tableHeaderCells[i].param;
        currentColumnNumber++;
      } else {
        reportSheet.mergeCells(
          headerRow.number,
          currentColumnNumber,
          headerRow.number,
          currentColumnNumber + tableHeaderCells[i].count - 1
        );
        reportSheet.getCell(headerRow.number, currentColumnNumber).value =
          tableHeaderCells[i].param;
        currentColumnNumber += tableHeaderCells[i].count;
      }
    }

    headerRow.alignment = { vertical: 'middle' };
    headerRow.font = { bold: true, underline: 'single' };
  };

  // -----Table generation-----

  generateTableHeader();

  const tableUnitsRow = createTableUnitsRow();
  const unitsRow = reportSheet.addRow(tableUnitsRow);
  unitsRow.alignment = { horizontal: 'right' };
  unitsRow.getCell(1).alignment = { horizontal: 'left' };

  const tableBodyRows = createTableBodyRows();

  const bodyRows = reportSheet.addRows(tableBodyRows);
  bodyRows.forEach((row, rowIndex) => {
    if (rowIndex === 0) {
      row.eachCell({ includeEmpty: false }, (cell) => {
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'FFCDD1DC'}
        };
      });
    }
    row.alignment = { horizontal: 'right' };
    row.getCell(1).alignment = { horizontal: 'left' };
  });

  // -----Table download-----

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;';
    let EXCEL_EXTENSION = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });

    FileSaver.saveAs(
      blob,
      `${currentVehicleName} ${dayjs(interval.startDate).format(
        dateTimeFormat
      )} - ${dayjs(interval.endDate).format(dateTimeFormat)}` + EXCEL_EXTENSION
    );
  } catch (e) {
    console.error(e);
  }
};
